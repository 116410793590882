



import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../../actions/systemActions";
import DataFilter from "../../components/DataFilter";
import PieBlock from "../../components/PieBlock";
import TableBlock from "../../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";


const TV = () => {
// State vars populated over redux.
const selectCompany = (state) => state.company;
const [animate, setAnimate] = useState(-1);
const { data, company } = useSelector(selectCompany);


const dispatch = useDispatch();


useEffect(() => {
setAnimate(animate => animate + 1);
}, [data]);


const page = "tv";



const selectSystem = (state) => state.system;
const { spinner } = useSelector(selectSystem);


/* 12th color is color of longtail channels */
const firstChartColors =
[
"rgba(61, 173, 208, 0.65)",
"rgba(61, 173, 208, 0.45)",
"rgba(61, 173, 208, 0.35)",
"rgba(61, 173, 208, 0.2)",
"rgba(61, 173, 208, 0.1)",
"rgba(191, 208, 0, 0.65)",
"rgba(191, 208, 0, 0.4)",
"rgba(191, 208, 0, 0.3)",
"rgba(191, 208, 0, 0.2)",
"rgba(191, 208, 0, 0.15)",
"rgba(191, 208, 0, 0.1)",
"rgba(61, 173, 208, 0.45)",
];


const secondChartColors =
[
"rgba(232, 78, 27, 0.85)",
"rgba(232, 78, 27, 0.65)",
"rgba(232, 78, 27, 0.45)",
"rgba(186,155,201,0.65)",
"rgba(186,155,201,0.35)",
"rgba(186,155,201,0.2)",
"rgba(186,155,201,0.1)",
"rgba(122, 106, 88, 0.35)",
"rgba(122, 106, 88, 0.54)",
"rgba(142, 106, 88, 0.65)",
"rgba(142, 106, 88, 0.85)",
"rgba(232, 78, 27, 0.35)",
"rgba(232, 78, 27, 0.2)",
"rgba(232, 78, 27, 0.55)",
];


const tableHeadings = [


{ name: "Channels" },
//{ name: "channels" ,key: 'channels_id:name' }, //*** to do **** */ this one
{ name: "CPG (EUR)", bar: "rgba(191, 208, 0, 1)", key: 'cpg' },
{ name: "GRP (%)", bar: "rgba(191, 208, 0, 0.6)", isPercentage: true, key: 'grp' },
{ name: "KTS (Mio.)", bar: "rgba(191, 208, 0, 0.5)", key: 'kts_mio' },
{ name: "NRW (%)", bar: "rgba(129, 202, 224, 1)", isPercentage: true, key: 'nrw' },
{ name: "NRW (Mio.)", bar: "rgba(129, 202, 224, 0.6)", key: 'nrw_mio' },
{ name: "TKP (EUR)", bar: "rgba(129, 202, 224, 1)", key: 'tkp' },
{ name: "OTS", bar: "rgba(210, 190, 220, 1)", key: 'ots' }
];


const firstTableHeadings = [

{ name: "" },
{ name: "year/month" },
{ name: "Channel", key: 'channels_id:name' },
{ name: "Discount/Channel", key: 'discount_channel_simulated', isPercentage: true, convert: true },
{ name: "Gross Rating Point (GRP)", key: 'gross_rating_point_grp_channel', numberOfDecimals: 2 },
{ name: "Gross Contacts in Mio.", key: 'gross_contacts_in_mio_target_group', numberOfDecimals: 2 },
{ name: "Net Costs", key: 'net_costs', numberOfDecimals: 2 },
{ name: "Visit per GRP", key: 'visit_per_grp', numberOfDecimals: 2 },
{ name: "Cost per Visit (net)", key: 'cost_per_visit_net', numberOfDecimals: 2 },
{ name: "Visits", key: 'visits' }

/*{ name: "gross_costs_in_euro", key: 'visit_per_grp' }

//gross_costs_in_euro
{ name: "Channel", key: 'channels_id:name' },
{ name: "Discount/Channel", key: 'discount_channel_simulated', isPercentage: true, convert: true },
{ name: "Gross Rating Point (GRP)", key: 'gross_rating_point_grp_channel', numberOfDecimals: 2 },
{ name: "Gross Contacts in Mio.", key: 'gross_contacts_in_mio_target_group', numberOfDecimals: 2 },
{ name: "Net Costs", key: 'net_costs', numberOfDecimals: 2 },
{ name: "Visit per GRP", key: 'visit_per_grp', numberOfDecimals: 2 },
{ name: "Cost per Visit (net)", key: 'cost_per_visit_net', numberOfDecimals: 2 },
{ name: "Visits", key: 'visits' }*/
];

const charts = [
{
title: "Channel Mix",
description: "TV Total:",
descriptionAppend: "k EURO",
id: "chm",
sheet: 1,
chartType: "pie",
dataType: "cm",
dataToPlot: "gross_costs_in_euro",
colors: firstChartColors,
toolTipAll: false,
endpoint: "cm",
group: true,
showTotal: true
},
{
title: "TimeZone Mix",
description: "Base:",
descriptionAppend: "% Budget",
id: "tzm",
sheet: 2,
chartType: "pie",
dataType: "tm",
dataToPlot: "gross_budget",
colors: secondChartColors,
toolTipAll: true,
endpoint: "tm",
showTotal: false
},
{
title: `${company?.name} KPIs Monthly`,
id: "once",
sheet: 1,
chartType: "table",
dataType: "c4",
headings: tableHeadings,
dataToPlot: "gross_costs_in_euro",
color: "#3DADD0",
endpoint: "c1",
monthly: true,
group: true
},
{
title: "Channel Report",
id: "te1",
sheet: 2,
chartType: "table",
//dataToPlot: "gross_costs_in_euro",
headings: firstTableHeadings,
dataType: "cm",
color: "#3DADD0",
endpoint: "cm",
},
];


const initialStateOfFilters = [];
initialStateOfFilters["0"] = "undefined";
initialStateOfFilters["1"] = "undefined";


const [selectedDates, setSelectedDates] = useState([]);
const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);


const containerWidth = document.getElementsByClassName("container-content")[0]
? (document.getElementsByClassName("container-content")[0])
.offsetWidth
: 0;


const printToPdf = async () => {
function getWidth() {
return Math.max(
document.body.scrollWidth,
document.documentElement.scrollWidth,
document.body.offsetWidth,
document.documentElement.offsetWidth,
document.documentElement.clientWidth
);
}
function getHeight() {
return Math.max(
document.body.scrollHeight,
document.documentElement.scrollHeight,
document.body.offsetHeight,
document.documentElement.offsetHeight,
document.documentElement.clientHeight
);
}
const params = {
pathname: "atl.tv",
selectedDates: selectedDates,
selectedFilters: [],
width: getWidth(),
height: getHeight(),
};
dispatch(systemActions.printToPdf(params));
};


let firstTableOptions = [];
let plotTest=[];


if (data[page]) {
if(data[page][0][0])
{
    plotTest=data[page][0]
    //alert(JSON.stringify(plotTest));/**aLL THE DATA IS HERE*/
}
firstTableOptions = data[page][0].map((item, index) => {
if (firstTableOptions.filter(e => e.label === item.channels_id.name).length === 0) {
return { value: index + 1, label: item.channels_id.name };
}
return undefined;
});
firstTableOptions.unshift({ value: 0, label: 'Select...' });
}


const calculateChartsWidth = () => {
if (containerWidth < 480) {
return containerWidth * 1.7;
} else if (containerWidth < 576) {
return containerWidth * 1.35;
} else if (containerWidth < 860) {
return containerWidth / 1.04;
} else if (containerWidth < 768) {
return containerWidth * 1.2;
} else if (containerWidth < 1030) {
return containerWidth / 1.4;
} else if (containerWidth < 1210) {
return containerWidth / 1.5;
} else {
return containerWidth / 2.2;
}
};


const calculateChartsHeight = () => {
if (containerWidth < 953) {
return containerWidth * 3.5;
} else {
return (containerWidth / 1.73) * 0.39;
}
};


return (
<div className="App atl">
<div style={{ display: "flex", flexDirection: "column" }}>
<div className="title-container">
<h2 className="title overview-title">ATL TV</h2>
<div style={{ display: "flex", alignItems: "center" }}>
<button
onClick={printToPdf}
className="btn btn-white download-as-pdf"
>
<>
PDF &nbsp;
{spinner ? (
<Spinner
radius={12}
color={TABLE_TEXT_COLOR}
stroke={2}
visible={true}
/>
) : (
<ArrowDown />
)}
</>
</button>
</div>
</div>


<DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
const updatedDates = [...selectedDates];
updatedDates["0"] = data;
setSelectedDates(updatedDates);
}}>
{(filteredData, date) => (
<div>
<div
style={{
display: "flex",
flexDirection: "column",
}}
className="top-charts-container"
>
<div
style={{
display: "flex",
flexDirection: "row",
}}
className="top-charts-container top-charts-container-atl"
>
<div className="chart-container chart-container-atl">
<PieBlock
containerId={`PieChart${charts[0].id}`}
title={charts[0].title}
description={charts[0].description}
descriptionAppend={charts[0].descriptionAppend}
data={filteredData}
key={0}
id={charts[0].id}
index={1}
chartType={charts[0].chartType}
height={data.length > 0 ? calculateChartsHeight() : "90px"}
width={calculateChartsWidth()}
sheet={charts[0].sheet}
dataType={charts[0].dataType}
dataToPlot={charts[0].dataToPlot}
animate={animate}
colors={charts[0].colors}
toolTipAll={charts[0].toolTipAll}
page={page}
showTotal={charts[0].showTotal}
/>
</div>


<div className="chart-container chart-container-atl">
<PieBlock
containerId={`PieChart${charts[1].id}`}
title={charts[1].title}
description={charts[1].description}
descriptionAppend={charts[1].descriptionAppend}
data={filteredData}
key={1}
id={charts[1].id}
index={2}
chartType={charts[1].chartType}
height={data.length > 0 ? calculateChartsHeight() : "90px"}
width={calculateChartsWidth()}
sheet={charts[1].sheet}
dataType={charts[1].dataType}
dataToPlot={charts[1].dataToPlot}
animate={animate}
colors={charts[1].colors}
toolTipAll={charts[0].toolTipAll}
page={page}
showTotal={charts[1].showTotal}
/>
</div>
</div>







</div>
</div>


)}
</DataFilter>

<DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;
          setSelectedDates(selectedDates);
        }}
         
        >
          {(data, date) => (
            <DataFilter data={data} page={page} options={firstTableOptions} onChange={data => {
              const updatedFilters = [...selectedFilters];
              updatedFilters["0"] = data.value;
              setSelectedFilters(updatedFilters);
            }} className="data-filter">
              {(filter) => (
                <div className="chart-container chart-container-atl table chart-container-fully-rounded">
                  <TableBlock
                    title={charts[3].title}
                    key={1}
                    id={charts[3].id}
                    index={1}
                    chartType={charts[3].chartType}
                    //height={(containerWidth / 2) * 0.39}
                    width={"100%"}
                    sheet={charts[3].sheet}
                    dataToPlot={filter && filter.value !== 0 ? { element: 'channels_id:name', value: filter } : null}
                    headings={charts[3].headings}
                    dataType={charts[3].dataType}
                    data={data}
                    date={date}
                    animate={animate}
                    color={charts[3].color}
                    page={page}
                  />
                </div>
              )}
            </DataFilter>)}
        </DataFilter>


</div>
</div >

);
};


export default TV;

