import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../actions/systemActions";
import DataFilter from "../components/DataFilter";
import TableBlock from "../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../constants/colors";

const NewEntryAlert = () => {
    // State vars populated over redux.
    const selectCompany = (state) => state.company;
    const [animate, setAnimate] = useState(-1);
    const { data } = useSelector(selectCompany);

    const dispatch = useDispatch();

    useEffect(() => {
        setAnimate(animate => animate + 1);
    }, [data]);

    const page = "competitive-intelligence-tv.new-entry-alert";

    const selectSystem = (state) => state.system;
    const { spinner } = useSelector(selectSystem);

    const charts = [
        {
            title: `Overview new competitor's entries`,
            id: "oncmpe",
            chartType: "table",
            //dataToPlot: "contacts_per_channel",
            endpoint: "ads",
        },
    ];

    const tableHeadings = [
        { name: "" },
        { name: "Preview", key: 'media:image:preview_xs', image: true, linkPrefix: "https://api.spotwatch.io" },
        { name: "Company", key: 'company' },
        { name: "Length", key: 'duration', appendText: "months" },
        { name: "Channel", key: 'channel', wrapText: true },
        { name: "Description", key: 'description', wrapText: true },
        { name: "View", key: 'media:video:mp4', linkPrefix: "https://api.spotwatch.io" }
    ];


    let options = [];
    if (data[page]) {
        if (!data[page][0].statusCode) {
            if (data[page][0]) {
                let index = 1;
                data[page][0].map((item) => {
                    if (options.filter(option => option.label === item.company).length === 0) {
                        return options.push({ value: index++, label: item.company });
                    }
                    return undefined;
                });
                options.unshift({ value: 0, label: 'Select...' });
            }
        }
    }

    const initialStateOfFilters = [];
    initialStateOfFilters["0"] = "undefined";
    initialStateOfFilters["1"] = "undefined";

    const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
    const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);


    const printToPdf = async () => {
        function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }
        function getHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        }
        const params = {
            pathname: "competitive-intelligence-tv.new-entry-alert",
            selectedDates: selectedDates,
            selectedFilters: selectedFilters,
            width: getWidth(),
            height: getHeight(),
        };
        dispatch(systemActions.printToPdf(params));
    };

    return (
        <div className="App atl competitors-1 new-entry-alert">
            <div className='page-content-container'>
                <div className="title-container">
                    <h2 className="title overview-title">New Entry Alert</h2>
                    <div style={{ display: "flex", alignItems: "center" }} className="pdf-button-container">
                        <button
                            onClick={printToPdf}
                            className="btn btn-white download-as-pdf"
                        >
                            <>
                                PDF &nbsp;
                                {spinner ? (
                                    <Spinner
                                        radius={12}
                                        color={TABLE_TEXT_COLOR}
                                        stroke={2}
                                        visible={true}
                                    />
                                ) : (
                                        <ArrowDown />
                                    )}
                            </>
                        </button>
                    </div>
                </div>

                <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
                    const updatedDates = [...selectedDates];
                    updatedDates["0"] = data;
                    setSelectedDates(updatedDates);
                }} className="competitors-date-picker">
                    {(data) => (
                        <DataFilter data={data} page={page} options={options} onChange={data => {
                            const updatedFilters = [...selectedFilters];
                            updatedFilters["0"] = data.value;
                            setSelectedFilters(updatedFilters);
                        }}
                            className="data-filter data-filter-competitors">
                            {(filter) => (
                                <div className='charts-wrapper'>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        className="top-charts-container"
                                    >


                                        <div className="chart-container table" style={{ borderRadius: '10px' }}>
                                            <TableBlock
                                                headings={tableHeadings}
                                                title={charts[0].title}
                                                key={0}
                                                id={charts[0].id}
                                                index={1}
                                                chartType={charts[0].chartType}
                                                //height={(containerWidth / 2) * 0.39}
                                                width={"100%"}
                                                sheet={charts[0].sheet}
                                                //dataToPlot={"contacts_per_channel"}
                                                data={data}
                                                dataType={"ads"}
                                                animate={animate}
                                                colors={charts[0].color}
                                                page={page}
                                                dataToPlot={filter && filter.value !== 0 ? { element: 'company', value: filter } : null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DataFilter>
                    )}
                </DataFilter>
            </div>
        </div >
    );
};

export default NewEntryAlert;
