import React, { useState, useEffect, useRef, Fragment } from "react";
import "./SideBar.css";
import { ReactComponent as Logo } from "./../assets/icons/mysmalllogo.svg";
//import { ReactComponent as Logo } from "./../assets/icons/cnqr-logo-small.svg";
import { ReactComponent as LogoMobile } from "./../assets/icons/cnqr-logo-mobile.svg";
import { ReactComponent as Dashboard } from "./../assets/icons/dashboard.svg";
import { ReactComponent as Chart } from "./../assets/icons/chart.svg";
import { ReactComponent as Laptop } from "./../assets/icons/laptop.svg";
import { ReactComponent as LogoutIcon } from "./../assets/icons/logout-icon.svg";
import { ReactComponent as UserIcon } from "./../assets/icons/user-icon.svg";
import { ReactComponent as BurgerMenuIcon } from "./../assets/icons/burger-menu-icon.svg";
import { ReactComponent as TvIcon } from "./../assets/icons/tv-icon.svg";
import { ReactComponent as OohIcon } from "./../assets/icons/ooh-icon.svg";
import { ReactComponent as PrintIcon } from "./../assets/icons/print-icon.svg";
import { ReactComponent as RadioIcon } from "./../assets/icons/radio-icon.svg";
//import { ReactComponent as TvWithPlayIcon } from "./../assets/icons/tv-with-play-icon.svg";
import { ReactComponent as LaptopChartIcon } from "./../assets/icons/laptop-chart-icon.svg";
import { ReactComponent as TrophyIcon } from "./../assets/icons/trophy-icon.svg";
import { ReactComponent as TimeIcon } from "./../assets/icons/time-icon.svg";
import { ReactComponent as Channels1Icon } from "./../assets/icons/channels-1-icon.svg";
import { ReactComponent as Channels2Icon } from "./../assets/icons/channels-2-icon.svg";
import { ReactComponent as Timezone1Icon } from "./../assets/icons/timezone-1-icon.svg";
import { ReactComponent as Timezone2Icon } from "./../assets/icons/timezone-2-icon.svg";
import { ReactComponent as Competitors1Icon } from "./../assets/icons/competitors-1-icon.svg";
import { ReactComponent as Competitors2Icon } from "./../assets/icons/competitors-2-icon.svg";
import { ReactComponent as RightArrowIcon } from "./../assets/icons/arrow-right-icon.svg";
import { ReactComponent as DownArrowIcon } from "./../assets/icons/arrow-down-without-line-icon.svg";
import { ReactComponent as CloseIcon } from "./../assets/icons/close-icon.svg";
import { THEMES } from "./../constants/themes.jsx";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../actions/userActions";
import useOutsideClick from "../hooks/useOutsideClickHook";
import useViewport from "../hooks/useViewportHook";
import useHover from "../hooks/useHoverHook";
const { LIGHT_THEME } = THEMES;
const STRAPI_URL = process.env.REACT_APP_STRAPI_URL;

const sideBarItemsTop = [
  { icon: Dashboard, route: "/overview", title: "Overview", name: "menu-alt-overview" },
  {
    icon: Chart, route: "/atl/tv", title: "ATL", name: "menu-atl", nestedItems: [
      {
        icon: TvIcon, route: "/atl/tv", title: "TV", name: "menu-atl-tv", nestedItems: [
          { icon: TimeIcon, route: "/atl/tv", title: "Time", name: "menu-atl-tv-time" },
          { icon: Channels1Icon, route: "/atl/tv/channels-1", title: "Channels 1", name: "menu-atl-tv-channels1" },
          { icon: Channels2Icon, route: "/atl/tv/channels-2", title: "Channels 2", name: "menu-atl-tv-channels2" },
          { icon: Timezone1Icon, route: "/atl/tv/timezone-1", title: "Timezone 1", name: "menu-atl-tv-timezone1" },
          { icon: Timezone2Icon, route: "/atl/tv/timezone-2", title: "Timezone 2", name: "menu-atl-tv-timezone2" },
          { icon: Competitors1Icon, route: "/atl/tv/competitors-1", title: "Competitors 1", name: "menu-atl-tv-competitors1" },
          { icon: Competitors2Icon, route: "/atl/tv/competitors-2", title: "Competitors 2", name: "menu-atl-tv-competitors2" },
        ]
      },
      { icon: OohIcon, route: "/atl/ooh", title: "OoH", name: "menu-atl-ooh" },
      { icon: PrintIcon, route: "/atl/print", title: "Print", name: "menu-atl-print" },
      { icon: RadioIcon, route: "/atl/radio", title: "Radio", name: "menu-atl-radio" },
      //{ icon: Laptop, route: "/atl/digital-tv", name: "menu-atl-digital-tv" },
    ]
  },
  { icon: Laptop, route: "/digital", title: "Digital", name: "menu-digital" },
  { icon: LaptopChartIcon, route: "/atl/digital-tv", title: "ATL + Digital", name: "menu-atl-and-digital" },
  //{ icon: TrophyIcon, route: "/competitors-analysis", title: "Competitors Analysis", name: "menu-competitor-analysis" },
  { icon: TrophyIcon, route: "/competitive-intelligence-tv/new-entry-alert", title: "Competitor Analysis", name: "menu-cit-new-entry-alert" },
  { icon: OohIcon, route: "/heatmaps", title: "Heatmaps", name: "menu-heatmaps-ooh" },
];

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();

  const selectCompany = useSelector((state) => state.company);
  const { company } = selectCompany;

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [burgerMenuIconLeftMargin, setBurgerMenuIconLeftMargin] = useState(0);

  const burgerMenuRef = useRef();
  const navItemRef = useRef();

  const notActiveItemRef = useRef();

  useOutsideClick(burgerMenuRef, () => {
    if (isMobileMenuOpened) {
      setIsMobileMenuOpened(false);
    }
  });

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showDropdownMenuSecondLevel, setShowDropdownMenuSecondLevel] = useState(false);

  const [isOtherMenuItemHovered, setIsOtherMenuItemHovered] = useState(false);

  useEffect(() => {
    if (!isOtherMenuItemHovered) {
      setShowDropdownMenu(false);
    }
  }, [isOtherMenuItemHovered]);

  const handleOpenMenuNestedItems = () => {
    setShowDropdownMenu(!showDropdownMenu);
  };

  const handleOpenDropdownMenuNestedItemsSecondLevel = () => {
    setShowDropdownMenuSecondLevel(!showDropdownMenuSecondLevel);
  };

  useHover(navItemRef, (value) => {
    setShowDropdownMenu(value);
  });

  useHover(notActiveItemRef, (value) => {
    setIsOtherMenuItemHovered(!value);
  });


  const sideBarItemsBottom = [
    { iconUrl: company ? `${STRAPI_URL}${company.logo?.formats.thumbnail.url}` : undefined, icon: UserIcon, route: "/profile", title: "Profile", name: "menu-profile" },
    { icon: LogoutIcon, title: "Logout", name: "menu-logout" }
  ];

  const dispatch = useDispatch();

  const [currentRoute, setCurrentRoute] = useState("/");

  const onClick = (route) => {
    if (route) {
      history.push(route);
      setCurrentRoute(route);

      showDropdownMenu && setShowDropdownMenu(false);

      if (isMobileMenuOpened) {
        onMobileMenuCloseClick();
      }
    };
  };

  const isRouteActive = (route) => {
    return currentRoute === route;
  };

  const logout = async () => {
    dispatch(userActions.logoutUser());
    history.push("/");
    history.go(0);
  };

  const renderImage = (url, isRouteActive) => {
    return <div style={{ backgroundImage: `url(${url})`, width: '100%', height: '100%', backgroundPosition: 'center', backgroundSize: 'cover' }} className={"sidebar-image-icon " + (isRouteActive && "sidebar-image-icon-active")} />;
  };

  const onMobileMenuOpenClick = () => {
    setIsMobileMenuOpened(true);
  };

  const onMobileMenuCloseClick = () => {
    setIsMobileMenuOpened(false);
  };

  const { width } = useViewport();

  // If screen width is greater than 768 px which is currently breakpoint (in css), close burger menu
  useEffect(() => {
    if (width > 768) {
      onMobileMenuCloseClick();
      setShowDropdownMenu(false);
    }
  }, [width]);

  useEffect(() => {
    setCurrentRoute(location.pathname);

    // Position burger menu icon dependent on page title width
    const titleContainer = document.getElementsByClassName('title')[0];
    const backToLinkContainer = document.getElementsByClassName('back-to-overview-link')[0];

    if (titleContainer) {
      setBurgerMenuIconLeftMargin(titleContainer.offsetWidth + 40);
    } else if (backToLinkContainer) {
      setBurgerMenuIconLeftMargin(backToLinkContainer.offsetWidth - 30);
    }
  }, [location.pathname]);

  useEffect(() => {
    // If mobile menu is showed disable page scroll in  background
    if (isMobileMenuOpened) {
      document.getElementsByClassName("container-content")[0].style.display = "none";
    } else {
      document.getElementsByClassName("container-content")[0].style.display = "initial";
    }
  }, [isMobileMenuOpened]);

  // const closedDropdownMenuStyles = { display: "none" };
  const openedSecondLevelOfDropdownStyles = { position: "absolute", left: "87px", display: showDropdownMenu ? "flex" : "none", width: "365px", height: "287px" };
  const closedSecondLevelOfDropdownStyles = { position: "absolute", left: "87px", display: showDropdownMenu ? "flex" : "none", width: "185px", height: "200px" };

  return (
    <>
      <div
        className="sidebar-container"
        style={{ backgroundColor: LIGHT_THEME.primary }}
      >
        <div className="sidebar-logo">
          <Logo />
        </div>
        <div className="sidebar-nav-items">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {sideBarItemsTop.map((item, index) => {
              // Is any of list items is active
              // Hover main icon if true
              const isAnyNestedRouteActive = item.nestedItems && item.nestedItems.some(
                (nestedItem) => {
                  const { nestedItems } = nestedItem;
                  if (nestedItems) {
                    return nestedItems.some(nestedItem => nestedItem.route === location.pathname);
                  }
                  return nestedItem.route === location.pathname;
                }
              );

              const { route: mainItemRoute, title, nestedItems } = item;
              return (
                <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div
                    ref={!showDropdownMenu && nestedItems ? navItemRef : null}
                    title={title}
                    className={"sidebar-item " + (isRouteActive(mainItemRoute) || isAnyNestedRouteActive ? "sidebar-item-active" : "") + " " + item.name}
                    style={{ backgroundColor: LIGHT_THEME.secondary }}
                    onClick={() => onClick(mainItemRoute)}
                  >
                    {<item.icon />}
                  </div>

                  {/* First level of dropdown menu */}

                  {nestedItems && (
                    <div ref={showDropdownMenu && nestedItems ? navItemRef : null} style={showDropdownMenuSecondLevel ? openedSecondLevelOfDropdownStyles : closedSecondLevelOfDropdownStyles}>
                      <div className="sidebar-item-dropdown-menu" style={!showDropdownMenu ? { display: "none" } : {}}>
                        {nestedItems && nestedItems.map((nestedItem, index) => {
                          const { route: nestedItemRoute, title, nestedItems: nestedItemsSecondLevel } = nestedItem;
                          const isAnyOfNestedItemsActive = nestedItem.nestedItems && nestedItem.nestedItems.some(nestedItem => nestedItem.route === location.pathname);
                          return (
                            <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                              {/* One item of first level of dropdown items */}
                              <div
                                title={title}
                                className={"sidebar-item-list " + (isRouteActive(nestedItemRoute) || isAnyOfNestedItemsActive ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                              >
                                <span className="sidebar-item-list-item" style={{ display: "flex", alignItems: "center" }} onClick={() => onClick(nestedItemRoute)}>
                                  <div className={"sidebar-item " + (isRouteActive(nestedItemRoute) || isAnyOfNestedItemsActive ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                  >
                                    {<nestedItem.icon />}
                                  </div>
                                  <span className="sidebar-item-list-title">{title}</span>
                                </span>

                                {nestedItemsSecondLevel && (
                                  <span className="sidebar-item-show-more-button" onClick={handleOpenDropdownMenuNestedItemsSecondLevel}>
                                    <RightArrowIcon />
                                  </span>
                                )}
                              </div>

                              {/* Second level of dropdown menu */}
                              {nestedItemsSecondLevel && (
                                <div style={!showDropdownMenuSecondLevel ? { display: "none" } : {}} className="sidebar-item-dropdown-menu sidebar-item-dropdown-menu-second-level">
                                  {nestedItemsSecondLevel && nestedItemsSecondLevel.map((nestedItem, index) => {
                                    const { route: nestedItemRoute, title } = nestedItem;
                                    return (
                                      // One item of second level of dropdown items
                                      <div
                                        key={index}
                                        title={title}
                                        className={"sidebar-item-list " + (isRouteActive(nestedItemRoute) ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                      >
                                        <span className="sidebar-item-list-item" style={{ display: "flex", alignItems: "center" }} onClick={() => onClick(nestedItemRoute)}>
                                          <div
                                            className={"sidebar-item " + (isRouteActive(nestedItemRoute) ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                          >
                                            {<nestedItem.icon />}
                                          </div>
                                          <span className="sidebar-item-list-title">{title}</span>
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                </div>
              );
            }
            )}
          </div>

          <div>
            {sideBarItemsBottom.map((item, index) => {
              const { route } = item;
              return (
                <div key={index} className={"sidebar-item bottom-menu-item " + (isRouteActive(route) ? "sidebar-item-active" : "")} style={{ backgroundColor: LIGHT_THEME.secondary }}
                  onClick={route ? () => onClick(route) : logout}>
                  {item.iconUrl ? renderImage(item.iconUrl, isRouteActive(route)) : <item.icon />}
                </div>
              );
            })}
          </div>
        </div>
      </div >

      {/* MOBILE MENU */}

      {/* Mobile menu */}
      <span onClick={onMobileMenuOpenClick} style={{ display: "none", position: "absolute", left: burgerMenuIconLeftMargin }} className="burger-menu-button"><BurgerMenuIcon /></span>

      {/* Burger menu */}
      <div ref={burgerMenuRef} style={isMobileMenuOpened ? { display: "flex", flexDirection: "column" } : { display: "none" }} className="burger-menu">
        <div className="burger-menu-content">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <span className="burger-menu-logo"><LogoMobile /></span>
            <span className="close-button" onClick={onMobileMenuCloseClick}><CloseIcon /></span>
          </div>
          <ul className="sidebar-item-list-container-mobile">
            {[...sideBarItemsTop, ...sideBarItemsBottom].map((item, index) => {
              const { route, title, nestedItems } = item;
              const isAnyNestedRouteActive = item.nestedItems && item.nestedItems.some(
                (nestedItem) => {
                  const { nestedItems } = nestedItem;
                  if (nestedItems) {
                    return nestedItems.some(nestedItem => nestedItem.route === location.pathname);
                  }
                  return nestedItem.route === location.pathname;
                }
              );
              return (
                <Fragment key={index}>
                  <div
                    key={index}
                    title={title}
                    className={"sidebar-item-list " + (isRouteActive(route) || isAnyNestedRouteActive ? "sidebar-item-list-active" : "") + " " + item.name}
                    style={{ width: "100%" }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }} onClick={route ? () => onClick(route) : logout}>
                      <div className={"sidebar-item " + (isRouteActive(route) || isAnyNestedRouteActive ? "sidebar-item-list-active" : "") + " " + item.name}
                      >
                        {<item.icon />}
                      </div>
                      <span className="sidebar-item-list-title">{title}</span>
                    </span>

                    {nestedItems && (
                      <span className="sidebar-item-show-more-button" onClick={handleOpenMenuNestedItems}>
                        <DownArrowIcon />
                      </span>
                    )}
                  </div>

                  {/* First level of dropdown menu */}
                  {nestedItems && (
                    <div className="sidebar-item-list-container" style={!showDropdownMenu ? { display: "none" } : {}}>
                      {nestedItems && nestedItems.map((nestedItem, index) => {
                        const { route: nestedItemRoute, title, nestedItems: nestedItemsSecondLevel } = nestedItem;
                        const isAnyOfNestedItemsActive = nestedItem.nestedItems && nestedItem.nestedItems.some(nestedItem => nestedItem.route === location.pathname);
                        return (
                          <Fragment key={index}>
                            <div key={index}>
                              {/* One item of first level of dropdown items */}
                              <div
                                title={title}
                                className={"sidebar-item-list " + (isRouteActive(nestedItemRoute) || isAnyOfNestedItemsActive ? "sidebar-item-list-active" : "") + " " + nestedItem.name}

                              >
                                <span style={{ display: "flex", alignItems: "center" }} onClick={() => onClick(nestedItemRoute)}>
                                  <div className={"sidebar-item " + (isRouteActive(nestedItemRoute) || isAnyOfNestedItemsActive ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                  >
                                    {<nestedItem.icon />}
                                  </div>
                                  <span className="sidebar-item-list-title">{title}</span>
                                </span>

                                {nestedItemsSecondLevel && (
                                  <span className="sidebar-item-show-more-button" onClick={handleOpenDropdownMenuNestedItemsSecondLevel}>
                                    <DownArrowIcon />
                                  </span>
                                )}
                              </div>
                            </div>

                            {/* Second level of dropdown menu */}
                            {nestedItemsSecondLevel && (
                              <div className="sidebar-item-list-container sidebar-items-list-container-second-level" style={!showDropdownMenuSecondLevel ? { display: "none" } : {}}>
                                {nestedItemsSecondLevel && nestedItemsSecondLevel.map((nestedItem, index) => {
                                  const { route: nestedItemRoute, title } = nestedItem;
                                  return (
                                    <div key={index}>
                                      {/* One item of first level of dropdown items */}
                                      <div
                                        title={title}
                                        className={"sidebar-item-list " + (isRouteActive(nestedItemRoute) ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                      >
                                        <span style={{ display: "flex", alignItems: "center" }} onClick={() => onClick(nestedItemRoute)}>
                                          <div className={"sidebar-item " + (isRouteActive(nestedItemRoute) ? "sidebar-item-list-active" : "") + " " + nestedItem.name}
                                          >
                                            {<nestedItem.icon />}
                                          </div>

                                          <span className="sidebar-item-list-title">{title}</span>
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default SideBar;
