import React, { useEffect, useState } from "react";
import "./../App.css";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../assets/icons/arrow-down.svg";
import DataFilter from "../components/DataFilter";
import BarBlock from "../components/BarBlock";
import TableBlock from "../components/TableBlock";
import { months } from "../data/dataOptions";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../constants/colors";
import Table from "../components/Table";
/**
 * A company profile page.
 *
 * The page displays all the thins of interest to the clients.
 * TODO: Name which things.
 *
 * @param props - For now just the user object
 */
const Digital = () => {
  // State vars populated over redux.
  const selectCompany = state => state.company;
  const { data } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const [animate, setAnimate] = useState(-3);
  const [noData, setNoData] = useState(false);
  const [dataToPlot, /*setDataToPlot*/] = useState();
  const [source, setSource] = useState();
  const page = "digital";

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

window.addEventListener('keydown', (e) => {


  
});
  const tableHeadings = [
    { name: "  ", format: ' ' },
    { name: "Source", key: 'sources_id:name' }, //regions_id for regions week_number
  
   // { name: "timestamp", key: 'timestamp' }, //regions_id for regions week_number
    
    { name: "impressions", key: 'impressions' },
    { name: "Frequency", key: 'frequency' },
    { name: "CPM", key: 'cpm', appendText: myCurrency  },
    { name: "Clicks", key: 'clicks' },
    { name: "CPC", key: 'cpc', appendText: myCurrency }, //change for equivalenst currency code 
    { name: "CTR", key: 'ctr', appendText: "%", title: "Click through rate" }, //to do** calculated field (clicks/impressions)
    { name: "FTD", key: 'conversion_1' },
    { name: "CPA_FTD", key: 'cpa', appendText: myCurrency  },////to do** calculated field  (clicks/impressions)
    { name: "Registration", key: 'conversion_2' },
    { name: "CPA_Registration", key: 'conv_2', appendText: myCurrency },
    { name: "DC", key: 'conversion_3'  },
    { name: "CPA_DC", key: 'concersion_3', appendText: myCurrency  },
    { name: "Total Spend", key: 'total_spend', appendText: myCurrency  },
    //  { name: "Length", key: 'duration', appendText: "months" },
  ];
 
  const selectSystem = (state) => state.system;
  const { spinner } = useSelector(selectSystem);
  let myCurrency=0;let currency=0; let totalconcersion3=0;let totalconv_2=0; let totalUniqueImpresions=0; let totalConv3=0;let totalConv2=0;let totalConv1=0;let totalftd=0; let totaltotal=0;let totalImpresions = 0; let totalcpm = 0; let totalcpc = 0; let totalcpa = 0; let totalclicks = 0; let totalctr = 0; let totalvtr = 0; let totalcpv = 0;
  const mymonth=0;
  useEffect(() => {
    if (!init) {
      if (Object.keys(data).length > 0 && data[page] && data[page].filter((item) => item.length > 0).length > 0) {
        if (noData) {
          setNoData(false);
        }
        else {
          setAnimate((animate) => animate + 1);
        }
      }
      else {
        if (animate => animate !== -2) {
          setAnimate(-2);
          setNoData(true);
        }
      }

      if(document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0])
      {
       document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].click();
      }
 
    }
    else {
      if (Object.keys(data).length > 0 && data[page]) {
        setAnimate((animate) => animate < -2 ? animate + 2 : animate + 1);
        setInit(false);
      }

     if(document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0])
     {
      document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].click();
     }


    }
  }, [data, init, noData]);

  const [groupBy, setGroupBy] = useState("regions_id");

  const charts = [
    {
      title: dataToPlot ? dataToPlot.label : 'Digital Performance ',
      id: "dc",
      sheet: [1, 1],
      chartType: "bar",
      //dataToPlot: "net_budget",
      color: ["#E84E1B", "#3DADD0"],
      endpoint: "dc",
      isLine: [1],
      group: groupBy,
    },
    {
      title: `Digital Performance`,
      id: "once",
      sheet: 2,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      endpoint: "c1",
      monthly: true,
    },

  ];

  const CUSTOMIZED_DROPDOWN_METRIC_LABELS = {
    conversion_1: "NDC",
    conversion_2: "Registration",
    conversion_3: "Deposit",
    post_click_conversion_1: "NDC PC",
    post_view_conversion_1: "NDC PV"
  };

  const initialStateOfFilters = [];
  initialStateOfFilters["0"] = "undefined";
  initialStateOfFilters["1"] = "undefined";
  /*initialStateOfFilters["2"] = "undefined";
  initialStateOfFilters["3"] = "undefined";*/

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
  const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);

  const containerWidth = document.getElementsByClassName("container-content")[0]
    ? document.getElementsByClassName("container-content")[0]
      .offsetWidth
    : 0;


    const mygroupBy = (items, key) => items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item,
        ],
      }), 
      {},
    );


  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
   
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params = {
       pathname: "digital",//****to do**** build test app to fix export pdf, do not commit  */
      selectedDates: selectedDates,
      selectedFilters: selectedFilters,
      width: getWidth(),
      height: getHeight(),
    };

    try{
        dispatch(systemActions.printToPdf(params));
    }catch(err)
    {
      alert(err.message);
    }
   
  };

  const formatLabel = (label) => {
    let split = label.replace(".id", " ").split('_');
    split[0] = split[0].charAt(0).toUpperCase() + split[0].slice(1);
    let formatted = split.join(' ');
    return formatted;
  };

  let options = [{ value: 0, label: 'Select...' }];
  if (data[page]) {
    if (data[page][0]) {
      if (data[page][0][0]) {
        let index = 1;
        data[page][0].forEach((bar, i) => {
          Object.keys(data[page][0][i])
            .filter(item => !['max_id', 'regions_id', 'sources_id','timestamp'].includes(item))
           // .sort((a, b)=> a.week_number-b.week_number)// sorting ***** To do ***** values by week number *** testing
            .forEach((key) => {
              const customizedKeyName = CUSTOMIZED_DROPDOWN_METRIC_LABELS[key];
              if (data[page][0][i]) {
                if (options.filter(option => option.value === key).length === 0 && data[page][0][i][key] && data[page][0][i][key] !== Infinity) {
                  options.push({ index: index++, value: key, label: customizedKeyName ? customizedKeyName : formatLabel(key) });
                }
              }
            });
          if (options.filter(option => option.label === 'Select...').length === 0) {
          
            //options.unshift({ index: 0, value: 0, label: 'Select... test 1' });
          }
        });
      }
    }
  }else
  {
//load by default on page load  *** to do **** , check init and setnodata above
try{
  document.getElementsByClassName("example-custom-input-1")[0].click();
}catch(err)
{
 //document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****

}

//document.getElementsByClassName("react-datepicker__month-1")[0].click();


  }
if(charts[1].title==="Digital Performance")
{
  try{ 
    
    var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
    //document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].focus();
  if(!kmonth)
  {//key is here
    //document.getElementsByClassName("example-custom-input-1")[0].click();
    //kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
  }


    if( kmonth)
    {

     
      var clickEvent = document.createEvent ("MouseEvents");
      clickEvent.initEvent ("click", true, true);
      kmonth.focus();

      var mytex = document.querySelectorAll("react-datepicker__month-text--keyboard-selected");	
      mytex[0].click();
    }else
    {
      //document.getElementsByClassName("example-custom-input-1")[0].click();

    }
  } catch(err)
 {
  //document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****

 }
 //document.getElementsByClassName("example-custom-input-1")[0].click();
}


  let options2 = [{ value: 0, label: 'Select...' }];///****To do**** pull the filter values from file as per file DG_Oct-March (3) 1st column , after the chart is generated *****
  //let options2 = [{ value: 0, label: 'Clear Filter' }];///****April 14th 2021, Reload loaded*** 



  if (data[page] && data[page].length) {
    data[page][0].forEach(row => {
      const { sources_id } = row;
      const { id, name } = sources_id;
      if (!options2.find(opt => opt.value === id && opt.label === name)) 
      {
        options2.push({ value: id, label: name });
      }
    });
  }else
  {
    //load report on landing ** to do**** swicth month selection 
    try{
        document.getElementsByClassName("example-custom-input-1")[0].click();

    }catch(err)
    {
     //document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****
   
    }
    


    //let oxjmonth= new Date(selectedDates[0]).getUTCMonth();
     /* document.getElementsByClassName("example-custom-input-1")[0].click();
     let mkmonth=document.getElementsByClassName("react-datepicker__month-4")[0];

    if(mkmonth)
    {

      document.getElementsByClassName("react-datepicker__month-4")[0].click()
    }*/

  }
  //alert(options2.length);/// test

  //const groupByFilters = ["regions.id", "week_number"];

  const groupByFilters = [ "sources.id"];//,"regions.id"];// **** updated on May 11th 2021 **** it should be regions_id , or source_id

  let groupByOptions = [];
  if (data[page]) {
    if (data[page][0]) {
      if (data[page][0][0]) {
        let index = 1;
        groupByOptions = groupByFilters
          .map((item) => {
            if (options.filter(option => option?.label === item).length === 0) {
              //return { index: index++, value: item, label: formatLabel(item) };
                 return undefined;
            }
            return undefined;
          });
      }
    }
  }

  const getDataToPlot = (data, dataLine) => {
    let dtp = ['impressions', 'total_spend'];
    if (data && (data.value && data.value !== 0)) {
      dtp[0] = data.value;
    }
    if (dataLine && dataLine.value !== 0) {
      dtp[1] = dataLine.value;
    }
    return dtp;
  };


// self executing function here
/*(function() {
  // your page initialization code here
  // the DOM will be available here
	var myte = document.querySelectorAll("table");	

  if(myte.length>0)
  {
    //alert(document.readyState);
  }else
  {

    //alert(document.readyState);
  }
  //add_table_titles(htmlx);
  
})();*/

  ///to do *** 
  function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();

    //alert("test");
}
///check content before push to excel ******to do *****
function export_table_to_csv(html, filename) {
  

  
  var csv = [];
	var rows = document.querySelectorAll("tr");	
  var heads=[];
/*
"Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " " + xjYear
xjYear= new Date(selectedDates[0]).getUTCFullYear();
**/

  //csv.push("Digital Performance - ," + monthNames[new Date(selectedDates[0]).getUTCMonth()] +","+ new Date(selectedDates[0]).getUTCFullYear());
  heads.push("");
  heads.push("");
  heads.push("Source");
  heads.push("Impressions");
  heads.push("Frequency");
  heads.push("CPM");
  heads.push("Clicks");
  heads.push("CPC");
  heads.push("CTR");
  heads.push("FTD");
  heads.push("CPA_FTD");
  heads.push("Registration");
  heads.push("CPA_Registration");
  heads.push("DC");
  heads.push("CPA_DC");
  heads.push("Total Spent");


    for (var i = 0; i < rows.length; i++) {
		var row = [], cols = rows[i].querySelectorAll("td");
		
    //row.push("test,test,test");

        for (var j = 0; j < cols.length; j++) 

            //var x=cols[j].innerText;
            //x=x.replace(".","");
           // x=x.replace(",",".");

           // alert(x);
            //row.push(x);
            if(heads[i])
            {

              if(j===cols.length-1)
              {
               
                switch(i)
                {
                    case 2:
                            row.push(cols[j].innerText.replace(",",".")+ ",Grand total");
                    break;
                    case 3://.toLocaleString('it-IT') 
                            row.push(cols[j].innerText.replace(",",".")+ "," + totalImpresions.toLocaleString('it-IT'));
                    break;
                    case 4:
                            row.push(cols[j].innerText.replace(",",".")+ "," + (totalImpresions/totalUniqueImpresions).toFixed(2) ); // element.impressions/element.unique_impressions;
                    break;
                    case 5:
                             row.push(cols[j].innerText.replace(",",".")+ ","+ ((totaltotal/totalImpresions) * 1000).toFixed(2) + " " + myCurrency ); // to do frequency
                    break;
                    case 6:
                             row.push(cols[j].innerText.replace(",",".")+ "," + totalclicks.toLocaleString('it-IT'));
                    break;
                    case 7:
                             row.push(cols[j].innerText.replace(",",".")+ "," + (totaltotal/totalclicks).toFixed(2)  + " " + myCurrency );
                    break;
                    case 8:
                          row.push(cols[j].innerText.replace(",",".")+ "," + ((totalclicks/totalImpresions).toFixed(3) * 100).toFixed(2)  +"%");
                   break;
                   case 9:
                            row.push(cols[j].innerText.replace(",",".")+ "," + totalConv1);
                   break;
                  case 10:
                    if(totalConv1>0)
                    {
                      row.push(cols[j].innerText.replace(",",".")+ "," + (totaltotal.toFixed(2)/(totalConv1)).toFixed(2) + " " + myCurrency);
                    }else
                    {
                      row.push("0"  + " " + myCurrency);
                    }
                    if(!totalConv1)
                    {

                      row.push("0" + " " + myCurrency );
                    }
         
                  break;
                  case 11:
                    if(totalConv2>0)
                    {
                          row.push(cols[j].innerText.replace(",",".")+ "," + totalConv2 ); // to do frequency
                    }else
                    {
                      row.push("0");
                    }
                    if(!totalConv2)
                    {
                      row.push("0");
                    }

                    break;
                  case 12:
                    if(totalConv2>0)
                    {
                      row.push(cols[j].innerText.replace(",",".") + "," + ((totaltotal.toFixed(2)) / (totalConv2)).toFixed(2) + " " + myCurrency ); // to do frequency      
                    }else
                    {
                      row.push("0" + " " + myCurrency );
                    }
                    if(!totalConv2)
                    {
                      row.push("0"+ " " + myCurrency );
                    }
                    break;
                  case 13:
                    if(totalConv3>0)
                    {
                          row.push(cols[j].innerText.replace(",",".") + "," + totalConv3.toLocaleString('it-IT'));
                    }else
                    {
                      row.push("0");
                    }

                    if(!totalConv3)
                    {
                      row.push("0");
                    }
                     break;
                  case 14:
                    if(totalConv3>0)
                    {
                      row.push(cols[j].innerText.replace(",",".")+ "," +((totaltotal.toFixed(2)) / (totalConv3)).toFixed(2)  + " " + myCurrency ); // to do frequency     
                    }else
                    {
                      row.push("0"+ " " + myCurrency );
                    }
                    if(!totalConv3)
                    {
                      row.push("0"+ " " + myCurrency );
                    }
                      break;
                  case 15:
                        row.push(cols[j].innerText.replace(",",".")+ "," + totaltotal.toFixed(2).toLocaleString('it-IT') + " " + myCurrency );
                break;


                   //parseInt(Math.round(100 * totalConv1) / 100) continue
                   
                }
               
              }else
              {
                 row.push(cols[j].innerText.replace(",","."));////***this is the key */
              }

             
             
              if(row[0].split(",")[0]!==heads[i] )
              {
                row[0]=heads[i] + "," + row[0];
               
              }
              
            }

          
            //row.push(cols[j].innerText); test
        
		csv.push(row.join(","));		
	}
  csv[0]="Digital Performance," + monthNames[new Date(selectedDates[0]).getUTCMonth()] +" "+ new Date(selectedDates[0]).getUTCFullYear();
  //rearrange the csv data 
  //csv.push("total");
  var mycsv = [];
  var myrow = [];
  var mycol=[];

  for (var ic = 0; ic < heads.length; ic++)
  {
    
    myrow.push(heads[ic]);
  }
  mycsv.push("");
  mycsv.push(",Digital Performance -" + monthNames[new Date(selectedDates[0]).getUTCMonth()] +" "+ new Date(selectedDates[0]).getUTCFullYear());
  mycsv.push("");
  myrow = [];
   
  var ii=0;
  var tempnumber=csv.length;

  
  if (Number.isInteger(csv[2].split(",").length/ 2)) {
   // alert("El número "+value+" es par");
   console.log("even number");
   //tempnumber=tempnumber+1;
} else {
    //alert("El número "+value+" es impar"); to do 
    console.log("un even length=" );
    tempnumber=tempnumber-1;
}
 
  for (var i = 3; i < tempnumber/2; i++) //only works for ** to do *** even numbers
  {

    var re = new RegExp(myCurrency, 'g');
    var ra = new RegExp(".", 'g');
//str.replace(re, '');

      var se=csv[i].split(",");
      var sources=csv[2].replace(re," ").split(",");
      console.log(sources );
     var temp=csv[3].replace(".","");
     var tempArray=temp.split(".");
     for(var cs=0;cs<=tempArray.length;cs++)
     {
        temp=temp.replace(".","");
     }
     //console.log(temp);

      var imp=temp.split(",");
     // imp=imp.replace(ra,"");//.split(",");
      var freq=csv[4].replace(re," ").split(",");
      var _cpm=csv[5].replace(re," ").split(",");
      //console.log(imp);
      var tempc=csv[6].replace(".","");
      var tempcArray=tempc.split(".");
      for(var cso=0;cso<=tempcArray.length;cso++)
      {
        tempc=tempc.replace(".","");
      }
     
      var _clicks=tempc.split(",");
     // console.log(_clicks);
      var _cpc=csv[7].replace(re," ").split(",");//.replace(myCurrency," ").split(",");
      var _ctr=csv[8].replace(re," ").split(",");//.replace(myCurrency," ").split(",");
     
     
      var tempci=csv[9].replace(".","");
      var tempciArray=tempci.split(".");
      for(var csoi=0;csoi<=tempciArray.length;csoi++)
      {
        tempci=tempci.replace(".","");
      }
     
      var _ftd=tempci.split(",");//.replace(myCurrency," ").split(",");
      var _ftdcpa=csv[10].replace(re," ").split(",");//.replace(myCurrency," ").split(",");
      
      
      var tempcg=csv[11].replace(".","");
      var tempcigArray=tempcg.split(".");
      for(var csoi=0;csoi<=tempcigArray.length;csoi++)
      {
        tempcg=tempcg.replace(".","");
      }
      
      
      var _reg=tempcg.split(",");//.replace(myCurrency," ").split(",");

      var _regcpa=csv[12].replace(re," ").split(",");//.replace(myCurrency," ").split(",");
     
      var tempdc=csv[13].replace(".","");
      var tempdcArray=tempdc.split(".");
      for(var csoid=0;csoid<=tempdcArray.length;csoid++)
      {
        tempdc=tempdc.replace(".","");
      }
      var _dc=tempdc.split(",");//.replace(myCurrency," ").split(",");
      var _dccpa=csv[14].replace(re," ").split(",");//.replace(myCurrency," ").split(",");
      
      var tempt=csv[15].replace(re,"");
      var temptArray=tempt.split(".");
     
      for(var csoidj=0;csoidj<=temptArray.length-2;csoidj++)
      {
        //tempt=tempt.replace(".","");

      }

      var _total=tempt.split(",");//.replace(myCurrency," ").split(",");

    //  console.log(_total);
     // imp=imp.replace(".",""); //testing

       if(se.length>0)
      {
        //for(var fr=0;fr<=se.length-2;fr++)
        
        myrow.push(" ");

        myrow.push(sources[ii]); //perfect 
        myrow.push(imp[ii]); //perfect 
        myrow.push(freq[ii]); //perfect 
        myrow.push(_cpm[ii]); //perfect 
        myrow.push(_clicks[ii]);
        myrow.push(_cpc[ii]);
        myrow.push(_ctr[ii]);
        myrow.push(_ftd[ii]);
        myrow.push(_ftdcpa[ii]);
        myrow.push(_reg[ii]);//_regcpa
        myrow.push(_regcpa[ii]);//_regcpa

        myrow.push(_dc[ii]);//_regcpa
        myrow.push(_dccpa[ii]);//_regcpa
       

        try {//csv.length-1
          if(_total[ii].split(".").length>1 && ii < _total.length-1)
          {

            //console.log(_total[ii].replace(".",""));
            //console.log(ii);
            if( _total[ii]!=_total[_total.length])
            {

                _total[ii]=_total[ii].replace(".","");
            }
          
          }else
          {

           // console.log(_total[ii]);
          }
        
      } catch (error) {
        //console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      myrow.push(_total[ii]);//_regcpa
        ii=ii+1;
       // myrow.push(se[1]);
       /*   myrow.push(se[2]);
        myrow.push(se[3]);
      myrow.push(se[3]);*/
             /*myrow.push(se[3]);
            myrow.push(se[4]);
            myrow.push(se[5]);*/

            try{
              //console.log(sources[ii]);
              //if(sources[ii])
              {
              mycsv.push(myrow.join(","));
              }
            }catch (error) {
              //console.error(error);
              // expected output: ReferenceError: nonExistentFunction is not defined
              // Note - error messages will vary depending on browser
            }
           
            myrow = [];
        
	
      }
     
    
      //  mycsv.push(myrow.join(","));	
  }
  
    // Download CSV
     download_csv(mycsv.join("\n"), filename); //testing
    //download_csv(csv.join("\n"), filename); // unc
}

//myexp


function add_table_titles(html) {

  var titles=[];
  titles.push("");
  titles.push("campaign Source");
  titles.push("Impressions");
  titles.push("Frequency");
  titles.push("Cost per Mile");
  titles.push("Clicks");
  titles.push("Cost per Click");
  titles.push("Click through rate");
  titles.push("Conversion 1");
  titles.push("Conversion 1 -Cost per action ");
  titles.push("Registration");
  titles.push("Registration -Cost per action ");
  titles.push("Conversion 3-DC ");
  titles.push("Conversion 3-Cost per action ");
  titles.push("Total spent");
  titles.push(" ");  titles.push("Grand Total");  titles.push("Grand Total Impressions");  titles.push("Grand Total Frequency");  titles.push("Grand Total Cost per Mile"); titles.push("Grand Total Clicks"); titles.push("Grand Total Cost per Click");   titles.push("Grand Total Click through Rate");  titles.push("Grand Total FTD");  titles.push("Grand Total FTD , Cost per action");  titles.push("Grand Total Registration");  titles.push("Grand Total Registration, Cost per action");  titles.push("Grand Total DC");  titles.push("Grand Total DC, Cost per action");  titles.push("Grand Total , Total Spent");  titles.push("");  titles.push("");

  var headers = document.querySelectorAll("th");
  for (var i = 0; i < headers.length; i++) {

    var two = headers[i];
  
    two.setAttribute("title", titles[i]);
  // alert(headers[i].innerHTML);
  }

}





/*

*/
function testing()
{
  //tableTotals
  var html = document.querySelector("table").outerHTML;
  add_table_titles(html);
	export_table_to_csv(html, "table.csv");

}

/*
document.querySelector(".download-as-pdf").addEventListener("click", function () {
  //  var html = document.querySelector("table").outerHTML;
//	export_table_to_csv(html, "table.csv");
});*/

  const addCalculatedFields = (data,param=0) => {


    let xrowCount=0;
    let xjmonth=0;
    let xjYear=0;
    if (selectedDates[0] !== "undefined")
    {
       xjmonth= new Date(selectedDates[0]).getUTCMonth();
       xjYear= new Date(selectedDates[0]).getUTCFullYear();

       //alert(xjYear);
     // alert(options2.value);
       //if(xjmonth>0)
      {
        xjmonth=xjmonth+ 1;
        //var d = new Date(selectedDates);
       // alert(xjmonth); month number ok , filter below ****** to do *****
       charts[1].title="Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " " + xjYear ;
      }
       
    }
    let temp = [];
    let tempIndex = 1; 
    let counter=2;
    let mytemp = [];
   
    if (data[page] && data[page].length) {
    
    //  alert(data[page].length);
     options2.forEach((e, i) => {//first loop

      let myClicks=0;   let myTotal=0;   let myUniqueImp=0; let myImp=0;let myConv1=0; let myConv2=0; let myConv3=0; let mycpm=0;let mycpc=0; let mycpa=0;let myctr=0;let myvtr=0;let mycpv=0;
        if(e.value!=0)
        {
          

          //alert(JSON.stringify())
         /* data[page][0].forEach((elementx, index) => {
          //alert(elementx.timestamp);
          });*/
         
          data[page][0].forEach((element, index) => {//second loop

//testing
if(new Date(element.timestamp*1000).getUTCMonth()!==xjmonth)
          {
            
          }else
          {}


            try{
            myCurrency= element.currencies_id.name;
           //{ name: "CPC", key: 'cpc', appendText: myCurrency }
           tableHeadings[4]= { name: "CPM", key: 'cpm', appendText: myCurrency  };
           tableHeadings[6]= { name: "CPC", key: 'cpc', appendText: myCurrency  };
           tableHeadings[9]= {name: "CPA_FTD", key: 'cpa', appendText: myCurrency  };
           tableHeadings[11]= { name: "CPA_Registration", key: 'conv_2', appendText: myCurrency };
           tableHeadings[13]= {  name: "CPA_DC", key: 'concersion_3', appendText: myCurrency  };
           tableHeadings[14]= {  name: "Total Spend", key: 'total_spend', appendText: myCurrency   };
          
           let elementMonth= new Date(element.timestamp*1000).getUTCMonth();
           elementMonth=elementMonth +1;

          if(elementMonth===6)
          {
            //alert(JSON.stringify(element)); 

          }
           ///** testing **///  
          //alert(element.timestamp + " , month: " + elementMonth + ",selected month: " + xjmonth);

            let elementYear= new Date(element.timestamp*1000).getUTCFullYear();
           
            if(elementMonth!==xjmonth + 1 && data[page].length >3)                   
            {       
                //alert(elementMonth + " - " + xjmonth ); //// testing **** to do *****
              //data[page][0].splice(1,1); , test this
            }
         
//alert(element.sources_id.name);//test

          if(e.label===element.sources_id.name && xjmonth===elementMonth && xjYear===elementYear)
          {

        //if()
          {
         //
          {
        if(element.timestamp===null)
        {
          //   alert("MediaMath month " + elementMonth)//;only april *** ok 
        }
            if( element.impressions )
            {
             // let    xjmonth= new Date(selectedDates[0]).getUTCMonth();
            // console.log(element);
           //  alert(element.timestamp);//(JSON.stringify(element));
             
              if( element.unique_impressions )
              {

                myUniqueImp=myUniqueImp + element.unique_impressions; 
              }
              
              myImp=myImp + element.impressions;
              totalImpresions=totalImpresions+ parseInt(element.impressions) ;
              totaltotal=totaltotal + element.total_spend;
              myTotal=myTotal + element.total_spend;
            }

              if(element.total_spend & element.impressions )
              {
                totalcpm = totalcpm +  (element.total_spend /parseInt(element.impressions) * 1000);
               
              }
              
              if(element.total_spend & element.clicks )
              {
                totalcpc=  totalcpc + (element.total_spend / element.clicks);
            
                myClicks=myClicks +  element.clicks;
              }
             // totalcpc=total_spend/totalclicks;
              
              if(element.total_spend & element.conversion_1 )
              {

                  totalcpa= totalcpa + (element.total_spend / element.conversion_1);
                  myConv1=myConv1 + (element.total_spend / element.conversion_1);
              }
              if(element.clicks & element.impressions )
              {
                  totalctr= totalctr + (element.clicks / parseInt(element.impressions));
              }
              if(element.video_complete & element.impressions )
              {
                  totalvtr= totalvtr + (element.video_complete / parseInt(element.impressions));
                   myvtr=myvtr+(element.video_complete / parseInt(element.impressions));
              }
              if(element.video_complete & element.total_spend )
              {
                    totalcpv= totalcpv + (element.total_spend / element.video_complete);
              }
              if(element.cpa)
              {
                    if(!isNaN(element.cpa))
                    {        
                       // totalftd+=parseInt(element.cpa);
                     //   totalcpa= totalcpa +parseInt(element.cpa);          //alert(totalftd);
                    }
              }      
              if(element.conversion_1)
              {

                //alert(el)
                  
                totalConv1=totalConv1 +  parseInt(element.conversion_1) ;// element.conversion_1;

              }else if(element.conversion_1===Infinity)
              {

                element.conversion_1=0;
              }
              if(element.conversion_2 >0)
              {
                totalConv2=totalConv2 + element.conversion_2;
                myConv2=myConv2 + element.conversion_2;
              }else if(element.conversion_2===Infinity)
              {
                element.conversion_2="0"; // aqui
                element.conv_2="0.0"; 
                //element.conversion_2=0;
              }
              if(element.conversion_3)
              {
                //if(!isNaN(element.conversion_3))
                {      
                     totalConv3+=parseInt(element.conversion_3);
                     myConv3=element.conversion_3;
                }
              } else if(element.conversion_3===Infinity)
              {

                element.conversion_3=0;
              }
              try{
                if(element.conversion_1 > 0)
                {
                  element.cpa =  element.total_spend / element.conversion_1;//totalcpa;// 
                }else
                {
                  element.conversion_1 ="0"; // aqui
                    element.cpa="0.0"; 
                }
                //  totalcpm = (element.total_spend / parseInt(element.impressions) * 1000) * 1000;//totalcpm +  (element.total_spend / element.impressions * 1000);
                  element.cpm = (element.total_spend / parseInt(element.impressions)) * 1000;//Fixing the formula; ***** April 28 2021******//
                  element.cpc =  element.total_spend / element.clicks;//totalcpc
                  
                  element.ctr = (element.clicks / parseInt(element.impressions)) * 100;//totalctr
                  element.vtr =  element.video_complete / parseInt(element.impressions);//totalvtr;//
                  element.cpv = element.total_spend / element.video_complete;//totalcpv;// e
                 element.frequency = element.impressions/element.unique_impressions;
                }catch(err)
                {
                      alert("error: " + err.message);
                }
              
                 switch (e.label)
                 {
                   case "Acquisition":
                    // element.frequency =2.92;// myImp/myUniqueImp;
                   break;
                   case "Remarketing":
                     //element.frequency =8.55;// myImp/myUniqueImp;
                   break;
                   case "Retention":
                     //element.frequency = 11.86;//myImp/myUniqueImp;
                   break;
                   default:
                    //element.frequency = 6.09;//myImp/myUniqueImp;
                   break;
                 }

                 if(element.conversion_2 > 0)
                 {
                  element.conv_2=element.total_spend / element.conversion_2;// test
                 }
                  if(!isNaN(element.conv_2))
                  {
                    if(element.conv_2)
                    {
                     totalconv_2=totalconv_2 + element.conv_2;
                    }
                  }else
                  {
                    element.conversion_2="0"; // aqui
                element.conv_2="0.0"; 
                  }
                
                  //"=total_spend/conversion_2" 
                  if(element.conversion_3 > 0)
                  {
                       element.concersion_3=element.total_spend / element.conversion_3;///**to do */
                  }else
                  {
                    element.conversion_3 ="0";
                    element.concersion_3="0.0";
                  }
                  //"=total_spend/conversion_2"
               // currency=element.currencies_id.name;


              if(element.concersion_3)
              {
                if(!isNaN(element.concersion_3))
                {
                totalconcersion3=totalconcersion3+ element.concersion_3;
                }
              }else  if(isNaN(element.concersion_3))
              {

                element.concersion_3=0;
              }
                
               if(element.unique_impressions)
               {
                  totalUniqueImpresions=totalUniqueImpresions + element.unique_impressions;

                  //alert(totalUniqueImpresions,"testing");
               }

                  totalclicks=totalclicks +  element.clicks;

          if(options2.length===2)
          {
            charts[1].title="Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " "+ new Date(selectedDates[0]).getFullYear()+ " - " + e.label;
          
          //  options2.unshif(i,e.value,e.label);//testing
          
          //options2.unshift({ index: 0, value: e.value, label: e.label });//test

            if(data[page][0].length>1)
           {

            for (let i = 0; i < 2; i++) 
            {
              ///test below code
 var myselecti= document.getElementsByClassName("sidebar-item sidebar-item-active menu-digital"); 
   myselecti[0].click();
 //  myselecti[0].click();
            }
         

           }
           // css-1uccc91-singleValue aqui
           /*var myselect= document.getElementsByClassName(" css-8n9463");
           var de;
           for(de=0;de<3;de++)
           {
             // myselect[0].click(); // reloads filter testing to do 
           }*/
          
          
          }
                  if(mytemp.includes(e.label))
                  {

                   
                  
                    if(new Date(element.timestamp*1000).getUTCMonth()!==xjmonth)
                    {
                     // alert(element.impressions);
                      data[page][0].splice(1,1);
                    }else  if(new Date(element.timestamp*1000).getUTCMonth()===xjmonth){

                      try{
                      element.impressions=myImp; //testing
                      if(isNaN(myConv1))
                      {
                        myConv1=0;
                      }
                      if(isNaN(myConv2))
                      {
                        myConv2=0;
                      }
                      if(isNaN(myConv3))
                      {
                        myConv3=0;
                      }
                      if(parseInt(myConv1)>0)
                      {
                         element.conversion_1=parseInt(myConv1);
                      }else
                      {

                        element.conversion_1="0";
                      }
                     
                     // mytemp.push(element.sources_id.name);
                      
                      element.conversion_2=myConv2;
                      element.conversion_3=myConv3;
                      element.clicks=myClicks;
                      element.total_spend=myTotal;
                      element.cpc =  myTotal/ myClicks;
                     if(myConv1 >0)
                     {
                       element.cpa=  myTotal/ myConv1;
                     }else
                     {
                       element.cpa="0.0";
                     }
                      
                      element.ctr = (myClicks / parseInt(myImp)) * 100;//totalctr
                      element.vtr = myvtr;
                      element.cpv = myTotal / element.video_complete;//totalcpv;// e
                      element.conv_2=element.total_spend / element.conversion_2;// test

                      
                    }catch(err)
                    {
                          alert("error: " + err.message);
                    }

                    if(options2.length<=2)
                      {
                          /*element.impressions=totalImpresions;
                          element.cpm=totalcpm;
                          element.cpc=totalcpc;
                          element.ctr=totalctr;alert("tesrt");*/
                      }


                    if(data[page][0].length===1)
                    {//to do ******
                        element.impressions=totalImpresions;
                       // alert("test");
                    }
                      tempIndex=tempIndex+1;

                      xrowCount=xrowCount +1;
                      //alert(tempIndex)
                    {

                      //data[page][0].splice(1,1);
                    }
                    
                    
                  }else
                  {

                    alert(element.impressions);
                  }
                   // grouping results below
                    //alert(element.sources_id.name);
                  }else
                  {
                   
                    //alert(element.ctr);
                    if(mytemp.includes(element.sources_id.name))
                    {
                       //data[page][0].splice(1,1); //test this ***** 
                    }else
                    {
                         //mytemp.push(element.sources_id.name);
                         //alert(element.cpm)
                    }
                   
                 
                    // 
                   //alert(element.timestamp);
                    //alert(element.sources_id.name);
                  }
              
                }
                  
              }

            }else
            {

              //data[page][0].splice(index,1);

              if(data[page][0].length>=5)
              {
                var i;
              //for (i = 0; i > data[page][0].length-4; i++) 
              {
               // data[page][0].splice(1,1);//delete empty rows
              }
                 
                
              }

              if(data[page][0].length===4)
              {
                
                ///data[page][0].splice(0,1);//delete empty rows testing

              }

              if(elementMonth!==xjmonth)                   
              {       
                data[page][0].splice(index,1);
              }
              
              if(elementYear!==xjYear)                   
              {       
                //data[page][0].splice(index,1);
              }

              if(xjmonth==12)
              {
                if(element.sources_id.name!=="Acquisition")
                {

                  //charts[1].title="Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " " + xjYear ;

                  //data[page][0].splice(index,1);//delete empty rows
                }
                //  data[page][0].splice(2,1);//delete empty rows

              }
              if(xjmonth===11)
              {
                if(element.sources_id.name!=="RTB" )
                {
                  //data[page][0].splice(index,1);//delete empty rows
                 // data[page][0].splice(0,1);//delete empty rows

                }
                //  data[page][0].splice(2,1);//delete empty rows

              }
              if(xjYear===2020 && xjmonth < 6)
              {
                if(elementYear!==xjYear)  
                {
                  data[page][0].splice(index,1);
                }
                //

              }


            }
        //counter=counter+1;
        /*if(data[page][0][index].ctr==="undefined")
        {
          data[page][0].splice(index,1);

        }*/
         //alert( data[page][0][index].ctr); //testing 
        }catch(merr)
        {
          alert(merr.message);

        }
      }//end second loop
    
);
    //  alert(totalcpm);//test

    }else if(xjmonth==12)
    {
      //  data[page][0].splice(2,1);//delete empty rows

    }
    counter=counter+1;
  });
//here
if(xjmonth==11)
{

 // alert( data[page][0].timestamp);
  // data[page][0].splice(2,1);//delete empty rows

}
if(charts[1].title==="Digital Performance")
{
  try{
    document.getElementsByClassName("example-custom-input-1")[0].click();
    var kmonthi=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0]
    if( kmonthi)
    {
      
      var clickEventi = document.createEvent ("MouseEvents");
      clickEventi.initEvent ("click", true, true);
      kmonthi.focus();
                        //continue
      //kmonthi.click();
      var mytexi = document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];	
      if(mytexi)
      {
        //mytexi.click();
        //alert("test");
      }
      //mytexi[0].click();document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0]
      
    }
  }catch(err)
  {}
}

if(totaltotal < 1)
{
  data[page][0].splice(1,1);
  totaltotal=0;
  totalConv1=0;
  totalConv2=0;
  totalConv3=0;
  try{
    document.getElementById("myexp").style.visibility= "hidden" ;
    //document.getElementById("myexp2").style.visibility= "hidden" ;
 // document.getElementById("resultTotals").style.visibility= "hidden" ;
  }catch(err)
  {
    /*let xjmonth=0;
    let xjYear=0;
    var juju = document.getElementsByClassName("example-custom-input-1")[0].value;
    var res = juju.split("/");
    xjmonth=res[0];
    xjYear=res[1];
    xjmonth=xjmonth-1; 
    const d = new Date(Date.UTC(xjYear, xjmonth));     // new Date(xjYear, xjmonth, 8, 1, 1, 1, 1);
    var frel= document.getElementsByClassName("example-custom-input-1")[0];*/
     
    if(charts[1].title==="Digital Performance")
    {
      try{
        document.getElementsByClassName("example-custom-input-1")[0].click();
      }catch(err)
      {}
    }
    
   /*
    document.getElementsByClassName("example-custom-input-1")[0].click();
    document.getElementsByClassName("example-custom-input-1")[0].click();
    var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
 
    if( kmonth)
    {
     var clickEvent = document.createEvent ("MouseEvents");
      clickEvent.initEvent ("click", true, true);
      kmonth.focus();

      var mytex = document.querySelectorAll("react-datepicker__month-text--keyboard-selected");	
      mytex[0].click();
    }*/
    //alert(kmonth);
    
  }

}else
{
  try{
  //document.body.style.zoom = "80%"; 
  //var elements = document.querySelectorAll('.newSearchResultsList li:not([style*="display:none"]):not([style*="display: none"])');
var xx=0;
	var myt = document.querySelectorAll("table");	
  myt[xx].style.zoom = "90%";
  
  //myt[0].setAttribute("zoom", "85%");

  myt[xx].deleteTFoot();
  var footer = myt[xx].createTFoot();
// Create an empty <tr> element and add it to the first position of <tfoot>:
var row = footer.insertRow(0);     


// Insert a new cell (<td>) at the first position of the "new" <tr> element:
var cell = row.insertCell(0);
cell.outerHTML = "<th></th>";
cell = row.insertCell(1);
cell.outerHTML = "<th align='left'>Grand Total</th>";
cell = row.insertCell(2);
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalImpresions) / 100).toLocaleString('it-IT') + "</th>";

cell = row.insertCell(3);
cell.outerHTML = "<th align='right'>"+ ((totalImpresions/totalUniqueImpresions)).toLocaleString('it-IT',{minimumFractionDigits: 2,maximumFractionDigits: 2}) +"</th>";

cell = row.insertCell(4);
cell.outerHTML = "<th align='right'>"+ ((totaltotal/totalImpresions) * 1000).toFixed(2).toLocaleString('it-IT').replace(".",",") + " " + myCurrency + "</th>";

cell = row.insertCell(5);
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalclicks) / 100).toLocaleString('it-IT').replace(",",".") +"</th>";

cell = row.insertCell(6);
cell.outerHTML = "<th align='right'> "+ (totaltotal/totalclicks).toFixed(2).toLocaleString('it-IT').replace(".",",")   + " "+ myCurrency + "</th>";

cell = row.insertCell(7);
cell.outerHTML = "<th align='right'>" + ((totalclicks/totalImpresions).toFixed(3) * 100).toFixed(2).replace(".",",")   +"%</th>";

cell = row.insertCell(8);
//cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalConv1) / 100)+"</th>";
//uncomment
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * (totalConv1)) / 100).toLocaleString('it-IT')+"</th>";

//cell.outerHTML = "<th align='right'>"+ totalConv1+"</th>";


cell = row.insertCell(9);
if(totalConv1>1)
{
cell.outerHTML = "<th align='right'>"+ (totaltotal.toFixed(2)/(totalConv1)).toFixed(2).toLocaleString('it-IT').replace(".",",")+ " " + myCurrency +"</th>";
}else
{
  cell.outerHTML = "<th align='right'>0" + myCurrency +"</th>";

}
cell = row.insertCell(10);
//cell.outerHTML = "<th align='right'>"+parseInt(Math.round(100 * (totalConv2)) / 100)+ "</th>";
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * (totalConv2)) / 100).toLocaleString('it-IT')+"</th>";

cell = row.insertCell(11);
if(totalConv2>1)
{
cell.outerHTML = "<th align='right'>"+ (totaltotal.toFixed(2) / (totalConv2)).toFixed(2).toLocaleString('it-IT').replace(".",",") + " " + myCurrency+"</th>";//totaltotal.toFixed(2)) / (totalConv2)).toFixed(2) }&nbsp;{myCurrency
}else
{
  cell.outerHTML = "<th align='right'>0"+ " " + myCurrency +"</th>";

}
cell = row.insertCell(12);
cell.outerHTML = "<th align='right'>"+ totalConv3.toLocaleString('it-IT')+"</th>";

cell = row.insertCell(13);
if(totalConv3>1)
{
     cell.outerHTML = "<th align='right'>"+ ((totaltotal.toFixed(2)) / (totalConv3)).toFixed(2).toLocaleString('it-IT').replace(".",",")+ " " + myCurrency+"</th>";
}else
{
  cell.outerHTML = "<th align='right'>0"+ " " + myCurrency +"</th>";

}
cell = row.insertCell(14);//d.toLocaleString("fr-FR");
cell.outerHTML = "<th align='right'>"+ totaltotal.toLocaleString('it-IT') +" " + myCurrency+"</th>";

var htmlx = document.querySelector("table").outerHTML;
  add_table_titles(htmlx);

//if(!element.cpm)
{
    //element.cpm=totalcpm;

}

 //  document.getElementById("resultTotals").style.visibility= "hidden" ;
   document.getElementById("myexp").style.visibility= "visible" ;
   //document.getElementById("myexp2").style.visibility= "visible" ;
  }
   catch(err)
    {
     // document.getElementById("react-select-2-input").tFoot.innerHTML)
       // alert(myt.length);///*** to do*****
        var mytt = document.querySelectorAll("table"); //// to do   move at the end of the process
        
        testO();
       
    }
}

      
    }
 
    return { [page]: [temp] };
  };


/* to do */
  return (
    <div className="App digital">
       
      <div className={`${page}`} style={{ display: "flex", flexDirection: "column" }}>
        <div className="title-container">
          <h2 className="title overview-title">Digital</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            
         


            
            <button id="myexp" style={{  visibility: 'hidden'}}
             
             className="btn btn-white download-as-pdf"
             title="Export CSV data"
             onClick={testing}
             
           
            >
              <>
                CSV &nbsp;
                 
                    <ArrowDown />
                  
              </>
            </button> 
            

            

          </div>
        </div>
        <DataFilter charts={charts} page={page} source={source} classSuffix="1" onChange={data => {
          if (data[1] !== null) {
            setAnimate((animate) => animate + 1);
          }

          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;//JSON.stringify({ startDate: data[0], endDate: data[1] });
          setSelectedDates(updatedDates);
           const xdate=updatedDates[0];
         
        }}>
          {filteredData => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
               className="top-charts-container"
               // className="competitors-date-picker"
              >
                <div className="chart-container top-container">
                 

                              <DataFilter data={addCalculatedFields(filteredData)} page={page}  autoSet={true} autoSetTo={0} options={options2} onChange={data => {
                               
                                setSource(data && data.value !== 0 ? data : null);
                                setAnimate(-1);
                                const updatedFilters = [...selectedFilters];
                                updatedFilters["3"] = data.index ? data.index : data.value;
                                setSelectedFilters(updatedFilters);
                             
                              }} className="data-filter">
                              
                                     {dataSource =>   <div id="mycontainer" className="chart-container table" style={{ borderRadius: '10px' }}>
                      <TableBlock
                        headings={tableHeadings}
                        title={charts[1].title}
                        key={0}
                        id={charts[1].id}
                        index={1}
                        chartType={charts[1].chartType}
                        width={"70%"}
                        sheet={charts[1].sheet}
                        data={filteredData}
                        animate={animate}
                        colors={charts[1].color}
                        page={page}
                        dataToPlot={data && data.value !== 0 ? { element: 'start_date', value: data } : null}
                        sortBy={'sources_id'}
                      />
                    
                    </div>}
                              </DataFilter>
                         
                </div>
              </div>
            </div> 
          )}
        </DataFilter>
      
       </div>
     
    </div>
 
  );
};

function testO(){
 
  ///test below code
 var myselect= document.getElementsByClassName("sidebar-item sidebar-item-active menu-digital");
  
 var i;
 //for(i=0;i<3;i++)
 {
   myselect[0].click();
 }
 
 
}
export default Digital;
