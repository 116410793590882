import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../../actions/systemActions";
import DataFilter from "../../components/DataFilter";
import TableBlock from "../../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

const Channels1 = () => {
  // State vars populated over redux.
  const selectCompany = (state) => state.company;
  const [animate, setAnimate] = useState(-1);
  const { data } = useSelector(selectCompany);

  const dispatch = useDispatch();

  useEffect(() => {
    setAnimate(animate => animate + 1);
  }, [data]);

  const pageFirstTable = "channels-first-table";
  const pageSecondTable = "channels-second-table";

  const selectSystem = (state) => state.system;
  const { spinner } = useSelector(selectSystem);

  const firstTableHeadings = [
    { name: "" },
    { name: "year/month" },
    { name: "Channel", key: 'channels_id:name' },
    { name: "Discount/Channel", key: 'discount_channel_simulated', isPercentage: true, convert: true },
    { name: "Gross Rating Point (GRP)", key: 'gross_rating_point_grp_channel', numberOfDecimals: 2 },
    { name: "Gross Contacts in Mio.", key: 'gross_contacts_in_mio_target_group', numberOfDecimals: 2 },
    { name: "Net Costs", key: 'net_costs', numberOfDecimals: 2 },
    { name: "Visit per GRP", key: 'visit_per_grp', numberOfDecimals: 2 },
    { name: "Cost per Visit (net)", key: 'cost_per_visit_net', numberOfDecimals: 2 },
    { name: "Visits", key: 'visits' }
  ];

  const secondTableHeadings = [
    { name: "" },
    { name: "year/month" },
    { name: "Channel", key: 'channels_id:name' },
    { name: "Discount/Channel", key: 'discount_channel_simulated', isPercentage: true, convert: true  },
    { name: "Gross Rating Point (GRP)", key: 'gross_rating_point_grp_channel', numberOfDecimals: 2 },
    { name: "Gross Contacts in Mio.", key: 'gross_contacts_in_mio_target_group', numberOfDecimals: 2 },
    { name: "Net Costs", key: 'net_costs', numberOfDecimals: 2 },
    { name: "Visit per GRP", key: 'visit_per_grp', numberOfDecimals: 2 },
    { name: "Cost per Visit (net)", key: 'cost_per_visit_net', numberOfDecimals: 2 },
    { name: "Visits", key: 'visits' }
  ];

  const charts = [
    {
      title: "Channel Report",
      id: "te1",
      sheet: 2,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      headings: firstTableHeadings,
      dataType: "cm",
      color: "#3DADD0",
      endpoint: "cm",
    },
    {
      title: "Channel Report",
      id: "te2",
      sheet: 2,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      headings: secondTableHeadings,
      dataType: "cm",
      color: "#3DADD0",
      endpoint: "cm",
    }
  ];
  const initialStateOfFilters = [];
  initialStateOfFilters["0"] = "undefined";
  initialStateOfFilters["1"] = "undefined";

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
  const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);

  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params = {
      pathname: "atl.tv.channels-1",
      selectedDates: selectedDates,
      selectedFilters: selectedFilters,
      width: getWidth(),
      height: getHeight(),
    };
    dispatch(systemActions.printToPdf(params));
  };

  let firstTableOptions = [];
  let secondTableOptions = [];

  if (data[pageFirstTable]) {
    firstTableOptions = data[pageFirstTable][0].map((item, index) => {
      if (firstTableOptions.filter(e => e.label === item.channels_id.name).length === 0) {
        return { value: index + 1, label: item.channels_id.name };
      }
      return undefined;
    });
    firstTableOptions.unshift({ value: 0, label: 'Select...' });
  }

  if (data[pageSecondTable]) {
    secondTableOptions = data[pageSecondTable][0].map((item, index) => {
      if (secondTableOptions.filter(e => e.label === item.channels_id.name).length === 0) {
        return { value: index + 1, label: item.channels_id.name };
      }
      return undefined;
    });
    secondTableOptions.unshift({ value: 0, label: 'Select...' });
  }

  useEffect(() => {
    const firstDatePicker = document.getElementsByClassName('tv-channels-first-date-picker')[0];
    const secondDatePicker = document.getElementsByClassName('tv-channels-second-date-picker')[0];

    // Move first date picker into first table container
    const firstTableContainer = document.getElementsByClassName('table-container-header')[0];
    firstTableContainer.append(firstDatePicker);

    // Move second date picker into second table container
    const secondTableContainer = document.getElementsByClassName('table-container-header')[1];
    secondTableContainer.append(secondDatePicker);

  }, [data]);



  return (
    <div className="App atl atl-channels">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="title-container">
          <h2 className="title overview-title">ATL TV</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={printToPdf}
              className="btn btn-white download-as-pdf"
            >
              <>
                PDF &nbsp;
                {spinner ? (
                  <Spinner
                    radius={12}
                    color={TABLE_TEXT_COLOR}
                    stroke={2}
                    visible={true}
                  />
                ) : (
                    <ArrowDown />
                  )}
              </>
            </button>
          </div>
        </div>

        <DataFilter charts={charts} page={pageFirstTable} classSuffix="1" onChange={data => {
          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;
          setSelectedDates(updatedDates);
        }}
          className="tv-channels-first-date-picker"
        >
          {(data, date) => (
            <DataFilter data={data} page={pageFirstTable} options={firstTableOptions} onChange={data => {
              const updatedFilters = [...selectedFilters];
              updatedFilters["0"] = data.value;
              setSelectedFilters(updatedFilters);
            }} className="data-filter">
              {(filter) => (
                <div className="chart-container table chart-container-fully-rounded">
                  <TableBlock
                    title={charts[0].title}
                    key={1}
                    id={charts[0].id}
                    index={1}
                    chartType={charts[0].chartType}
                    //height={(containerWidth / 2) * 0.39}
                    width={"100%"}
                    sheet={charts[0].sheet}
                    dataToPlot={filter && filter.value !== 0 ? { element: 'channels_id:name', value: filter } : null}
                    headings={charts[0].headings}
                    dataType={charts[0].dataType}
                    data={data}
                    date={date}
                    animate={animate}
                    color={charts[0].color}
                    page={pageFirstTable}
                  />
                </div>
              )}
            </DataFilter>)}
        </DataFilter>

        <DataFilter charts={charts} page={pageSecondTable} classSuffix="2" onChange={data => {
          const updatedDates = [...selectedDates];
          updatedDates["1"] = data;
          setSelectedDates(updatedDates);
        }}
          className="tv-channels-second-date-picker">
          {(data, date) => (
            <DataFilter data={data} page={pageSecondTable} options={secondTableOptions} onChange={data => {
              const updatedFilters = [...selectedFilters];
              updatedFilters["1"] = data.value;
              setSelectedFilters(updatedFilters);
            }}
              className="data-filter">
              {(filter) => (
                <div className="chart-container table chart-container-fully-rounded">
                  <TableBlock
                    title={charts[1].title}
                    key={2}
                    id={charts[1].id}
                    index={1}
                    chartType={charts[1].chartType}
                    //height={(containerWidth / 2) * 0.39}
                    width={"100%"}
                    sheet={charts[1].sheet}
                    headings={charts[1].headings}
                    dataToPlot={filter && filter.value !== 0 ? { element: 'channels_id:name', value: filter } : null}
                    dataType={charts[1].dataType}
                    data={data}
                    date={date}
                    animate={animate}
                    color={charts[1].color}
                    page={pageSecondTable}
                    className="table-container-fully-rounded"
                  />
                </div>
              )}
            </DataFilter>)}
        </DataFilter>
      </div>
    </div >
  );
};

export default Channels1;
