import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Spinner from "react-spinner-material";
import * as userActions from "../actions/userActions";
import { IUserState, IFormInput, ILocationState } from "../interfaces";
import { WHITE } from "./../constants/colors.jsx";
const LOST_PASSWORD_URL = "password";
/**
 * Register or login component.
 *
 * Displays one or the other on the same page depending on the 'mode' parameter
 * which is set in component state. Defaults to 'login'.
 */
const RegisterOrLogin = () => {
  // react-hook-form handles form validation.
  const { handleSubmit, register, errors } = useForm<IFormInput>();
  let history = useHistory();
  let location = useLocation();
  const selectUser = (state: IUserState) => state.user;
  const { error, spinner } = (useSelector(selectUser) as unknown) as IUserState;
  const { statusCode, message } = error ?? {};
  const dispatch = useDispatch();
  // Use local state instead of redux, cause this is local component stuff.
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mode, setMode] = useState("Sign In");
  /**
   * Text input handler. Sets changed text to component state.
   *
   * @param e - Input field on change event.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    name === "email" ? setEmail(value) : setPassword(value);
  };
  /**
   * Main.
   *
   * Multi
   * line.
   *
   * @param {any} input - Description
   *  multiline
   * @param {string} align - Two
   * @param {Object} layout - Three
   * @public
   * @see
   * @return {Array}
   *
   * @example
   *   code
   */
  //const commentTemplate = 1;
  /**
   * Form submit handler.
   *
   * Gathers form data and dispatches login/register actions to redux
   * the result of which is either a filled 'error' variable here or
   * a redirection to ProfilePage with a filled 'user' object. The
   * redirect is happening in App.tsx.
   *
   * @param d - Data from the form.
   */
  const onSubmit = async (d: IFormInput) => {
    let { from } = (location.state as ILocationState) || {
      from: { pathname: "/" },
    };
    const data: IFormInput = {
      ...d,
      username: email,
      identifier: email,
      history,
      from: from.pathname,
    };
    dispatch(
      mode === "Sign In"
        ? userActions.loginUser(data)
        : userActions.registerUser(data)
    );
  };
  // @see react-hook-form docs
  const sub = handleSubmit(onSubmit);
  const redirectToRecoverPage = () => {
    history.push(LOST_PASSWORD_URL);
  };

  return (
    <div className="App">
      <div className="cnqr-logo"></div>
      <div className="form-container">
        <h1 className="form-header">{mode}</h1>
        <form onSubmit={sub}>
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              value={email}
              placeholder="example@example.com"
              onChange={handleChange}
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors.email && (
              <span className="error form-message">{errors.email.message}</span>
            )}
          </div>
          <div className="form-control">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              id="password"
              type="password"
              value={password}
              placeholder="Password"
              onChange={handleChange}
              ref={register({
                required: "Required",
              })}
            />
            {errors.password && (
              <span className="error">{errors.password.message}</span>
            )}
          </div>
          {mode === "Sign In" && (
            <div className="form-options">
              <span
                onClick={redirectToRecoverPage}
                className="cursor-pointer text-green link"
              >
                Forgot password?
              </span>
            </div>
          )}
          <button id="submit" className="btn" type="submit">
            {spinner ? (
              <>
                <span>Wait...</span>&nbsp;
                <Spinner radius={12} color={WHITE} stroke={2} visible={true} />
              </>
            ) : (
              mode
            )}
          </button>
        </form>
        {mode === "Sign In" && (
          <p
            onClick={() => setMode("Sign Up")}
            className="cursor-pointer text-green link"
            style={{ display: "none" }}
          >
            Don't have an account? Sign up
          </p>
        )}
        {mode === "Sign Up" && (
          <p
            onClick={() => setMode("Sign In")}
            className="cursor-pointer text-green link"
          >
            Do you have an account? Sign in
          </p>
        )}
        <span className="form-message" style={{ textAlign: "center" }}>
          {error && !statusCode && message}
        </span>
      </div>
    </div>
  );
};
export default RegisterOrLogin;
